import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import '../scss/app.scss';
import 'bootstrap/dist/js/bootstrap.bundle'
import './bootstrap';
import '@vueform/slider/themes/default.css';
import "leaflet/dist/leaflet.css";


import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import BootstrapVueNext from 'bootstrap-vue-next';
import VueTheMask from 'vue-the-mask';

const appName = import.meta.env.VITE_APP_NAME || 'Çelik 02';

import AOS from 'aos';
import 'aos/dist/aos.css';

import store from "./state/store";
import i18n from './i18n'

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createInertiaApp({
    title: (title) => `${appName} - ${title}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        return createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(store)
            .use(i18n)
            .use(ZiggyVue)
            .use(VueTheMask)
            .use(BootstrapVueNext)                     
            .mount(el);
    },
    progress: {
        color: '#4B5563',
    },
});
